import { useContext } from 'react'
import './style.scss'
import VolumeSlider from '../../molecules/VolumeSlider'
import Select from '../../atoms/Select'
import { SettingsContext } from '../../../SettingsContext'
import { VoiceContext } from '../../../VoiceContext'

export default function Settings() {
  const {SettingsState, UpdateSettingsState} = useContext(SettingsContext)
  const {VoiceState} = useContext(VoiceContext)

  const SetMasterVolume = (NewGain: number) => {
    SettingsState.MasterVolume = NewGain
    UpdateSettingsState()
  }

  const SetMasterVolumeMuted = (Muted: boolean) => {
    SettingsState.MasterVolumeMuted = Muted
    UpdateSettingsState()
  }

  const SetMicVolume = (NewGain: number) => {
    SettingsState.MicVolume = NewGain
    UpdateSettingsState()
  }

  const SetMicVolumeMuted = (Muted: boolean) => {
    SettingsState.MicVolumeMuted = Muted
    UpdateSettingsState()
  }

  let SelectedDevice = SettingsState.AudioDevice === null ? "default" : SettingsState.AudioDevice.deviceId

  let Devices = [{value: "default", label: "Default device"}]
  VoiceState.AudioDevices.forEach((Device) => {
    if (Device.deviceId === "default") return
    Devices.push({value: Device.deviceId, label: Device.label })
  })

  const SetSelectedDevice = (deviceId: string) => {
    if (deviceId === "default") {
      SettingsState.AudioDevice = null
    } else {
      VoiceState.AudioDevices.some((device) => {
        if (deviceId === device.deviceId) {
          SettingsState.AudioDevice = device
          return true
        } else {
          return false
        }
      })
    }
    UpdateSettingsState()
  }

  return (
    <div className='settings'>
      <h2>Settings</h2>
      <div className='container'>
        <div className='setting'>
          <h3>Master volume</h3>
          <VolumeSlider Gain={SettingsState.MasterVolume} SetGain={SetMasterVolume} Muted={SettingsState.MasterVolumeMuted} SetMuted={SetMasterVolumeMuted} AltColor={true}/>
        </div>
        <div className='setting'>
          <h3>Microphone volume</h3>
          <VolumeSlider Gain={SettingsState.MicVolume} SetGain={SetMicVolume} Muted={SettingsState.MicVolumeMuted} SetMuted={SetMicVolumeMuted} AltColor={true} IsMic={true}/>
        </div>
        <div className='setting'>
          <h3>Microphone device</h3>
          <Select SelectedItemValue={SelectedDevice} Items={Devices} SetValue={SetSelectedDevice} AltColor={true}/>
        </div>
      </div>
    </div>
  )
}