import './style.scss'

type TProps = {
  padding?: string
  margin?: string
}

export default function VerticalDivider({padding = "4px 0", margin = "0 8px"}: TProps) {

  return (
    <div className="verticaldivider" style={{padding: padding, margin: margin}}></div>
  )
}