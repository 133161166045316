import './style.scss'

import speaker from "./svg/Speaker.svg"
import speakermuted from "./svg/SpeakerMuted.svg"
import microphone from "./svg/Microphone.svg"
import microphonemuted from "./svg/MicrophoneMuted.svg"

type TProps = {
  Value?: boolean
  SetValue: (NewValue: boolean)=>void
  IsMic?: boolean
}

export default function ToggleIcon({Value = false, SetValue, IsMic = false}: TProps) {
  let svg: string = "";
  if (IsMic) {
    if (Value) svg = microphonemuted
    else svg = microphone
  } else {
    if (Value) svg = speakermuted
    else svg = speaker
  }

  return (
      <img className='toggleicon' src={svg} onClick={() => {SetValue(!Value)}} alt="toggle icon"/>
  )
}