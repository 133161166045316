import React from 'react';
import WaitingScreen from '../../organisms/WaitingScreen';
import Playerlist from '../../organisms/Playerlist';
import Settings from '../../organisms/Settings';
import './style.scss'

export default function Main() {
  return (
    <div>
      <WaitingScreen />
      <div className='maincontent'>
        <div className='container'>
          <Playerlist/>
          <Settings/>
        </div>
      </div>
    </div>
  )
}