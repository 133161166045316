import { useContext } from 'react'
import './style.scss'
import { GameContext } from '../../../GameContext'
import { VoiceContext } from '../../../VoiceContext'

import Player from '../../molecules/Player'

export default function Playerlist() {
  const GameState = useContext(GameContext)
  const { VoiceState, UpdateVoiceState } = useContext(VoiceContext)

  if (GameState.Server.Login == null || GameState.Server.Login === "") {
    return (
      <div className='playerlist none'>
        <h1 className='altcolor'>Not connected to any server</h1>
      </div>
    )
  } else if (VoiceState.ServerAllowed === null) {
    return (
      <div className='playerlist none'>
        <h1 className='altcolor'>Requesting access to the server</h1>
      </div>
    )
  } else if (VoiceState.ServerAllowed === false) {
    return (
      <div className='playerlist none'>
        <h1 className='altcolor'>Server not allowed</h1>
      </div>
    )
  }

  return (
    <div className='playerlist'>
      <h1>Server <span className='altcolor'>{ GameState.Server.Name }</span></h1>
      <div className='container'>
        {Object.entries(VoiceState.Players).map(([Login, PlayerInfo]) => {
          if (GameState.Players.hasOwnProperty(Login)) {
            return <Player key={Login} Login={Login} PlayerInfo={PlayerInfo} OnUpdate={UpdateVoiceState}/>
          } else {
            return null
          }
        })}
      </div>
    </div>
  )
}