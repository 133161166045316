import './style.scss'
import Chevron from'./svg/Chevron.svg'
type TItem = {
  value: string
  label: string
}

type TProps = {
  AltColor?: boolean
  SelectedItemValue: string
  Items: TItem[]
  SetValue: (NewValue: string) => void
}


export default function Select({SelectedItemValue, Items, AltColor=false, SetValue}: TProps) {
  return (
    <div className="select">
      <img src={Chevron} alt="chevron down icon"/>
      <select className={ AltColor ? 'altcolor': ''} value={SelectedItemValue} onChange={e => SetValue(e.target.value)}>
        {Items.map((item) => {
          return <option key={item.value} value={item.value}>{item.label}</option>
        })}
      </select>
    </div>
  )
}