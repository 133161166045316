import React from 'react';

import SettingsProvider from './SettingsContext';
import GameProvider from './GameContext';
import VoiceProvider from './VoiceContext';
import Main from './components/pages/Main';

export default function App() {

  //InitServerCommunication()

  return (
    <SettingsProvider>
      <GameProvider>
        <VoiceProvider>
          <Main/>
        </VoiceProvider>
      </GameProvider>
    </SettingsProvider>
  );
}
