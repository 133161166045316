import { createContext, useState, useEffect, useRef, PropsWithChildren} from 'react';

let Timeout: NodeJS.Timeout

export interface IUser {
	Login: string
    Name: string
}

export interface IPosition {
	X: number
	Y: number
	Z: number
}

export interface IPlayer extends IUser {
	Position: IPosition|null
}

export interface IPlayers {
	[key: string]: IPlayer
}

export interface ILocalPlayer extends IPlayer {
	Direction: IPosition|null
}

export interface IGameState {
	LocalPlayer: ILocalPlayer
	Server: IUser
	Players: IPlayers
}

const DEFAULT_GAMESTATE = {
	LocalPlayer: {Login: "", Name: "", Position: null, Direction: null},
	Server: {Login: "", Name: ""},
	Players: {}
}

export const GameContext = createContext<IGameState>(DEFAULT_GAMESTATE)

export default function GameProvider({ children }: PropsWithChildren<{}>) {
	const SocketRef = useRef<WebSocket|null>(null)
	const [SocketWaitForReconnect, SetSocketWaitForReconnect] = useState<boolean>(false)
	const [GameState, SetGameState] = useState<IGameState>(DEFAULT_GAMESTATE)

	useEffect(() => {
		if (SocketWaitForReconnect) return
		if (SocketRef.current !== null) return
		console.log("[GAME] Init Socket") 
		SocketRef.current = new WebSocket('ws://127.0.0.1:5432')

		createTimeout()

		// Connection opened
		SocketRef.current.addEventListener('open', OnOpen)
		SocketRef.current.addEventListener('message', OnMessage)
		SocketRef.current.addEventListener('error', OnError)
		SocketRef.current.addEventListener('close', OnClose)
	}, [SocketWaitForReconnect]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		console.log('[GAME] Adding "beforeunload" event')
		window.addEventListener('beforeunload', function (event: BeforeUnloadEvent) {
			console.log('[GAME] Closing Websocket')
			SocketRef.current?.close()
		})
	}, [])

	function createTimeout() {
		if (Timeout !== null) clearTimeout(Timeout)
		Timeout = setTimeout(() => {
			console.warn('[GAME] Cancelling Websocket connection: timeout')
			SetGameState(DEFAULT_GAMESTATE)

			SocketRef.current?.close()
			SocketRef.current?.removeEventListener('open', OnOpen)
			SocketRef.current?.removeEventListener('message', OnMessage)
			SocketRef.current?.removeEventListener('error', OnError)
			SocketRef.current?.removeEventListener('close', OnClose)

			SocketRef.current = null

			SetSocketWaitForReconnect(true)
			setTimeout(() => {
				SetSocketWaitForReconnect(false)
			}, 1000)
		}, 5000)
	}

	const OnOpen = () => {
		console.log('[GAME] Connection established')
	}
	const OnMessage = (event: MessageEvent) => {
		SetGameState({...DEFAULT_GAMESTATE, ...JSON.parse(event.data)} as IGameState)
		createTimeout()
	}
	const OnError = (event: Event) => {
		console.error('[GAME] Error with the Websocket', event)
	}
	const OnClose = () => {
		console.log('[GAME] Websocket closed')
		SocketRef.current = null

		SetSocketWaitForReconnect(true)
		setTimeout(() => {
			SetSocketWaitForReconnect(false)
		}, 1000)
		SetGameState(DEFAULT_GAMESTATE)
	}

	return (
        <GameContext.Provider value={ GameState }>
            { children }
        </GameContext.Provider>
    )
}



