import './style.scss'

type TProps = {
  Value: number
  SetValue: (NewValue: number)=>void
}

export default function Slider({Value, SetValue}: TProps) {
  return (
      <input className="slider" type="range" min="0" max="1" step="0.01" value={Value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {SetValue(e.target.valueAsNumber)}}/>
  )
}