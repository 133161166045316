import { createContext, useState, useEffect, PropsWithChildren} from 'react';

export interface ISettingsState {
	SettingsLoaded: boolean
	MasterVolume: number
	MasterVolumeMuted: boolean
	MicVolume: number
	MicVolumeMuted: boolean
	AudioDevice: MediaDeviceInfo|null
}

const DEFAULT_SETTINGSSTATE = {
	SettingsLoaded: false,
	MasterVolume: 1.0,
	MasterVolumeMuted: false,
	MicVolume: 1.0,
	MicVolumeMuted: false,
	AudioDevice: null
}

interface IContext {
	SettingsState: ISettingsState
	UpdateSettingsState: Function
}

const DEFAULT_CONTEXT = {
	SettingsState: DEFAULT_SETTINGSSTATE,
	UpdateSettingsState: ()=>{}
}

export const SettingsContext = createContext<IContext>(DEFAULT_CONTEXT)

export default function SettingsProvider({ children }: PropsWithChildren<{}>) {
	const [SettingsState, SetSettingsState] = useState<ISettingsState>(DEFAULT_SETTINGSSTATE)

	useEffect(() => {
		console.log("[SETTINGS] Loading settings from localStorage")
		let settings = localStorage.getItem("settings")
		if (settings !== null) {
			SetSettingsState({...DEFAULT_SETTINGSSTATE, ...JSON.parse(settings) as ISettingsState, SettingsLoaded: true })
		} else {
			SetSettingsState((PrevSettingsContext) => ({ ...PrevSettingsContext, SettingsLoaded: true}))
		}
	}, [])


	useEffect(() => {
		console.log("[SETTINGS] Saving settings to localStorage")
		localStorage.setItem("settings", JSON.stringify(SettingsState))
	}, [SettingsState])

	function UpdateSettingsState() {
		console.log("[SETTINGS] Update SettingsState from UI")
		SetSettingsState({...SettingsState})
	}
	
	return (
        <SettingsContext.Provider value={{ SettingsState, UpdateSettingsState } }>
            { children }
        </SettingsContext.Provider>
    )
}



