export enum EMessageType {
	Ping, // from API
	Authentication, // from Client 
	JoinServer, // from Client 
	LeaveServer, // from Client 
	UserConfig, // from API
	ServerNotAllowed, // from API
	AddRTCPlayers, // from API 
	RemoveRTCPlayer, // from API
    RTCOffer, // Both
	RTCAnswer, // Both
	RTCIceCandidate, //Both
}

export interface IMessage {
	Type: EMessageType
}

export const DEFAULT_MESSAGE_PING: IMessage = {
	Type: EMessageType.Ping
}

export interface IMJoinLeave extends IMessage {
	Login: string
}
export const DEFAULT_MESSAGE_AUTHENTICATION: IMJoinLeave = {
	Type: EMessageType.Authentication,
	Login: ""
}
export const DEFAULT_MESSAGE_JOINSERVER: IMJoinLeave = {
	Type: EMessageType.JoinServer,
	Login: ""
}
export const DEFAULT_MESSAGE_LEAVESERVER: IMJoinLeave = {
	Type: EMessageType.LeaveServer,
	Login: ""
}

export interface IMUserConfig extends IMessage {
	Distance: number,
	RTCConfig: RTCConfiguration
}
export const DEFAULT_MESSAGE_USERCONFIG: IMUserConfig = {
	Type: EMessageType.UserConfig,
	Distance: 50.,
	RTCConfig: {}
}

export const DEFAULT_MESSAGE_SERVERNOTALLOWED: IMJoinLeave = {
	Type: EMessageType.ServerNotAllowed,
	Login: ""
}

export interface IRTCPlayer {
	Login: string,
	IsInitiator: boolean
}
export interface IMAddRTCPlayers extends IMessage {
	Players: IRTCPlayer[]
}
export const DEFAULT_MESSAGE_ADDRTCPLAYERS: IMAddRTCPlayers = {
	Type: EMessageType.AddRTCPlayers,
    Players: []
}
export const DEFAULT_MESSAGE_REMOVERTCPLAYER: IMJoinLeave = {
	Type: EMessageType.RemoveRTCPlayer,
	Login: ""
}

export interface IRTCRelayMessage extends IMessage {
	From: string,
	To: string,
	Payload: RTCSessionDescriptionInit|RTCIceCandidate|null
}

export const DEFAULT_MESSAGE_RTCOFFER: IRTCRelayMessage = {
	Type: EMessageType.RTCOffer,
	From: "",
    To: "",
    Payload: null
}
export const DEFAULT_MESSAGE_RTCANSWER: IRTCRelayMessage = {
	...DEFAULT_MESSAGE_RTCOFFER,
	Type: EMessageType.RTCAnswer
}
export const DEFAULT_MESSAGE_RTCICECANDIDATE: IRTCRelayMessage = {
	...DEFAULT_MESSAGE_RTCOFFER,
	Type: EMessageType.RTCIceCandidate
}