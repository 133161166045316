
import "./style.scss";

type TProps = {
    height: string
}

export default function LoadingAnimation(props: TProps) {
    return (
        <div className="loadinganimation" style={{height: props.height}}>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    )
}