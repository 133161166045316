import { useContext } from 'react'
import { IPlayerState } from '../../../VoiceContext'
import VolumeSlider from '../VolumeSlider'
import './style.scss'
import { GameContext } from '../../../GameContext'

type TProps = {
  Login: string
  PlayerInfo: IPlayerState
  OnUpdate: Function
}

export default function Player({Login, PlayerInfo, OnUpdate}: TProps) {
  const GameState = useContext(GameContext)

  const SetGain = (NewGain: number) => {
    PlayerInfo.GainValue = NewGain
    OnUpdate()
  }

  const SetMuted = (NewMuted: boolean) => {
    PlayerInfo.Muted = NewMuted
    OnUpdate()
  }

  return (
    <div className="player">
      <h2>{ GameState.Players[Login]?.Name }</h2>
      <VolumeSlider Gain={PlayerInfo.GainValue} SetGain={SetGain} Muted={ PlayerInfo.Muted } SetMuted={SetMuted}/>
    </div>
  )
}