import Slider from '../../atoms/Slider'
import ToggleIcon from '../../atoms/ToggleIcon'
import VerticalDivider from '../../atoms/VerticalDivider'
import './style.scss'

type TProps = {
  AltColor?: boolean
  IsMic?:boolean
  Gain: number
  SetGain: (NewValue: number) => void
  Muted: boolean
  SetMuted: (NewValue: boolean) => void
}

export default function VolumeSlider({AltColor = false, IsMic = false, Gain, SetGain, Muted, SetMuted}: TProps) {
  return (
    <div className={ AltColor ? 'slidervolume altcolor': 'slidervolume'}>
      <Slider Value={Gain} SetValue={SetGain}/>
      <VerticalDivider/>
      <ToggleIcon Value={Muted} SetValue={SetMuted} IsMic={IsMic}/>
    </div>
  )
}