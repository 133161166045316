import { createContext, useState, useEffect, useRef, PropsWithChildren, useContext} from 'react'
import { GameContext, IPosition } from './GameContext'
import {
	DEFAULT_MESSAGE_LEAVESERVER,
	DEFAULT_MESSAGE_JOINSERVER,
	DEFAULT_MESSAGE_AUTHENTICATION,
	DEFAULT_MESSAGE_USERCONFIG,
	DEFAULT_MESSAGE_RTCOFFER,
	DEFAULT_MESSAGE_RTCANSWER,
	DEFAULT_MESSAGE_RTCICECANDIDATE,
	EMessageType,
	IRTCPlayer,
	IMUserConfig,
} from './commons/VoiceInterfaces'
import { SettingsContext } from './SettingsContext'

export interface IRTCContext {
	PeerConnection: RTCPeerConnection|null,
	PlayerGain: GainNode|null,
	Panner: PannerNode|null,
	DummyAudioDom: HTMLAudioElement|null,
}

const DEFAULT_RTCCONTEXT = {
	PeerConnection: null,
	PlayerGain: null,
	Panner: null,
	DummyAudioDom: null
}

export interface IRTCContexts {
	[key: string]: IRTCContext
}


export interface IPlayerState {
	GainValue: number
	Muted: boolean
}

export interface IPlayersState {
	[key: string]: IPlayerState,
}

export interface IVoiceState {
	Connected: boolean
	ServerAllowed: boolean|null
	Players: IPlayersState
	AudioDevices: MediaDeviceInfo[]
}

const DEFAULT_VOICESTATE = {
	Connected: false,
	ServerAllowed: null,
	Players: {},
	AudioDevices: []
}


interface IContext {
	VoiceState: IVoiceState
	UpdateVoiceState: Function
}

const DEFAULT_CONTEXT = {
	VoiceState: DEFAULT_VOICESTATE,
	UpdateVoiceState: ()=>{}
}

export const VoiceContext = createContext<IContext>(DEFAULT_CONTEXT)

export default function VoiceProvider({ children }: PropsWithChildren<{}>) {
	const GameState = useContext(GameContext)
	const {SettingsState} = useContext(SettingsContext)
	const SocketRef = useRef<WebSocket|null>(null)
	const RTCConnectionsRef = useRef<IRTCContexts>({})
	const [SocketWaitForReconnect, SetSocketWaitForReconnect] = useState<boolean>(true)
	const [VoiceState, SetVoiceState] = useState<IVoiceState>(DEFAULT_VOICESTATE)

	const [CurrentServer, SetCurrentServer] = useState<string>("")

	const UserConfigRef = useRef<IMUserConfig>(DEFAULT_MESSAGE_USERCONFIG)
	const MainAudioContextRef = useRef<AudioContext|null>(null)
	const AudioSourceRef = useRef<MediaStreamAudioSourceNode|null>(null)
	const MasterGainNodeRef = useRef<GainNode|null>(null)
	const MicGainNodeRef = useRef<GainNode|null>(null)
	const [Stream, SetStream] = useState<MediaStream|null>(null)

	useEffect(() => {
		if (Stream === null) return
		if (SocketWaitForReconnect) return
		if (SocketRef.current !== null) return
		if (GameState.LocalPlayer.Login === "") return
		console.log("[VOICE] Init Socket", process.env.REACT_APP_API_URL!) 
		SocketRef.current = new WebSocket(process.env.REACT_APP_API_URL!)

		// Connection opened
		SocketRef.current.addEventListener('open', OnOpen)
		SocketRef.current.addEventListener('message', OnMessage)
		SocketRef.current.addEventListener('error', OnError)
		SocketRef.current.addEventListener('close', OnClose)
		return () => {
			SocketRef.current?.close()
		}
	}, [SocketWaitForReconnect, Stream]) // eslint-disable-line react-hooks/exhaustive-deps
	

	useEffect(() => {
		if (!SettingsState.SettingsLoaded) return
		console.log('[VOICE] Adding "beforeunload" event')
		window.addEventListener('beforeunload', function (event) {
			console.log('[VOICE] Triggering "beforeunload" event')
			clearValues()
		})

		console.log('[VOICE] Ask media permission')
		if (navigator.mediaDevices != null) {
			navigator.mediaDevices.getUserMedia(getUserMediaConstraints()).then((stream: MediaStream) => {
				// Creating Audio context (must be after user action (auto accepting the permissions works too))
				console.log('[VOICE] Creating new AudioContext')
				MainAudioContextRef.current = new AudioContext()

				// creating input
				AudioSourceRef.current = MainAudioContextRef.current.createMediaStreamSource(stream)
				const Input_AudioDest = MainAudioContextRef.current.createMediaStreamDestination()
				MicGainNodeRef.current = MainAudioContextRef.current.createGain()
				AudioSourceRef.current.connect(MicGainNodeRef.current)
				MicGainNodeRef.current.connect(Input_AudioDest)
				setGain(MicGainNodeRef.current, SettingsState.MicVolume, SettingsState.MicVolumeMuted)
				SetStream(Input_AudioDest.stream)

				// creating Output
				const Output_AudioDest = MainAudioContextRef.current.createMediaStreamDestination()
				MasterGainNodeRef.current = MainAudioContextRef.current.createGain()
				MasterGainNodeRef.current.connect(Output_AudioDest)
				setGain(MasterGainNodeRef.current, SettingsState.MasterVolume, SettingsState.MasterVolumeMuted)
				
				const audiodom = document.createElement('audio') as HTMLAudioElement
				document.body.appendChild(audiodom)
				audiodom.setAttribute('autoplay', '')
				audiodom.srcObject = Output_AudioDest.stream

				updateAudioDevices()

				// update Audio Device list if any changes
				navigator.mediaDevices.addEventListener("devicechange", (event) => {
					updateAudioDevices();
				});  
			}).catch((error: Error) => {
				console.error("[VOICE] Permission denied", error)
			})
		} else {
			console.error("[VOICE] mediaDevices are undefined. Probably because the connection is not safe")
		}
		
	}, [SettingsState.SettingsLoaded]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (GameState.LocalPlayer.Login === "" && SocketRef.current !== null) {
			SocketRef.current.close()
			clearValues()
		} else if (GameState.LocalPlayer.Login !== "") {
			if (SocketRef.current === null) {
				if (SocketWaitForReconnect) {
					SetSocketWaitForReconnect(false)
				} else {
					SetSocketWaitForReconnect(true)
					setTimeout(() => {
						SetSocketWaitForReconnect(false)
					}, 10)
				}
				return
			}

			if (SocketRef.current.readyState !== 1) return

			if (CurrentServer !== GameState.Server.Login) {
				if (CurrentServer !== undefined && CurrentServer !== "") {
					SocketRef.current?.send(JSON.stringify({...DEFAULT_MESSAGE_LEAVESERVER, Login: CurrentServer}))
				}
				
				cleanRTCConnections()
				SetCurrentServer(GameState.Server.Login)
				SetVoiceState((PrevVoiceState) => ({ ...PrevVoiceState, ServerAllowed: null}))
				UserConfigRef.current = DEFAULT_MESSAGE_USERCONFIG

				return
			}

			Object.entries(RTCConnectionsRef.current).forEach(([Login, RTCPlayer]) => {
				if (GameState.Players.hasOwnProperty(Login) && GameState.Players[Login].Position != null && GameState.LocalPlayer.Position != null) {
					const VectorMeToPlayer: IPosition = {
						X: GameState.Players[Login].Position!.X - GameState.LocalPlayer.Position.X,
						Y: GameState.Players[Login].Position!.Y - GameState.LocalPlayer.Position.Y,
						Z: GameState.Players[Login].Position!.Z - GameState.LocalPlayer.Position.Z,
					};
				
					const RelativePosition: IPosition = {
						X: VectorMeToPlayer.Z * GameState.LocalPlayer.Direction!.X - VectorMeToPlayer.X * GameState.LocalPlayer.Direction!.Z,
						Y: VectorMeToPlayer.Y,
						Z: VectorMeToPlayer.X * GameState.LocalPlayer.Direction!.X + VectorMeToPlayer.Z * GameState.LocalPlayer.Direction!.Z,
					};

					RTCPlayer.Panner?.positionX.setValueAtTime(RelativePosition.X, RTCPlayer.Panner?.context.currentTime) 
					RTCPlayer.Panner?.positionY.setValueAtTime(RelativePosition.Y, RTCPlayer.Panner?.context.currentTime) 
					RTCPlayer.Panner?.positionZ.setValueAtTime(RelativePosition.Z, RTCPlayer.Panner?.context.currentTime) 


				} else {
					RTCPlayer.Panner?.positionX.setValueAtTime(9999, RTCPlayer.Panner?.context.currentTime) 
					RTCPlayer.Panner?.positionY.setValueAtTime(9999, RTCPlayer.Panner?.context.currentTime) 
					RTCPlayer.Panner?.positionZ.setValueAtTime(9999, RTCPlayer.Panner?.context.currentTime) 
				}
			})
		}
	}, [GameState]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (CurrentServer !== undefined && CurrentServer !== "") {
			SocketRef.current?.send(JSON.stringify({...DEFAULT_MESSAGE_JOINSERVER, Login: CurrentServer}))
		}
	}, [CurrentServer])
	
	useEffect(() => {
		if (!SettingsState.SettingsLoaded) return
		if (!VoiceState.Connected) return

		if (SettingsState.AudioDevice?.deviceId !== AudioSourceRef.current?.mediaStream.getAudioTracks()[0].getConstraints().deviceId) {
			const Constraints = getUserMediaConstraints()
			console.log("[VOICE] Updating UserMedia with new contraints", Constraints)

			navigator.mediaDevices.getUserMedia(Constraints).then((stream: MediaStream) => {
				AudioSourceRef.current?.disconnect()

				// creating input
				AudioSourceRef.current = MainAudioContextRef.current!.createMediaStreamSource(stream)
				AudioSourceRef.current.connect(MicGainNodeRef.current!)
			}).catch((error: Error) => {
				console.error("[VOICE] Permission denied", error)
			})
		}

		setGain(MasterGainNodeRef.current, SettingsState.MasterVolume, SettingsState.MasterVolumeMuted)
		setGain(MicGainNodeRef.current, SettingsState.MicVolume, SettingsState.MicVolumeMuted)

		Object.entries(VoiceState.Players).forEach(([Login, PlayerInfo]) => {
			if (RTCConnectionsRef.current.hasOwnProperty(Login)) {
				setGain(RTCConnectionsRef.current[Login].PlayerGain, PlayerInfo.GainValue, PlayerInfo.Muted)
			}
		})
	}, [VoiceState, SettingsState]) // eslint-disable-line react-hooks/exhaustive-deps

	const OnOpen = () => {
		console.log('[VOICE] Connection established')

		SocketRef.current?.send(JSON.stringify({...DEFAULT_MESSAGE_AUTHENTICATION, Login: GameState.LocalPlayer.Login}))

		SetVoiceState((PrevVoiceState) => ({ ...PrevVoiceState, Connected: true}))
	}
	const OnMessage = (event: MessageEvent) => {
		let message: any
		try {
			message = JSON.parse(event.data)
		} catch (error) {
			console.warn("[VOICE] Can't parse json", event.data)
			return
		}
		
		if (!message.hasOwnProperty("Type")) {
			console.warn("[VOICE] Invalid json object", message)
			return
		} 

		switch (message.Type) {
			case EMessageType.Ping: break
			case EMessageType.UserConfig: {
				console.log("[VOICE] Received User config")
				SetVoiceState((PrevVoiceState) => ({ ...PrevVoiceState, ServerAllowed: true}))
				UserConfigRef.current = message as IMUserConfig
				break
			}
			case EMessageType.ServerNotAllowed: {
				console.log("[VOICE] Received Server not allowed")
				SetVoiceState((PrevVoiceState) => ({ ...PrevVoiceState, ServerAllowed: false}))
				break
			}
			case EMessageType.AddRTCPlayers: {
				message.Players.forEach((RTCPlayer: IRTCPlayer) => {
					if (GameState.LocalPlayer.Login === RTCPlayer.Login) {
						console.warn("[VOICE] Received Offer to connect to myself")
						return
					}

					if (RTCConnectionsRef.current.hasOwnProperty(RTCPlayer.Login)) {
						console.warn("[VOICE] RTCPeerConnection already exists", RTCPlayer)
						return
					}
					console.log("[VOICE] Creating RTCPeerConnection", RTCPlayer)
					RTCConnectionsRef.current[RTCPlayer.Login] = {...DEFAULT_RTCCONTEXT}

					const PeerConnection = new RTCPeerConnection(UserConfigRef.current.RTCConfig)
					RTCConnectionsRef.current[RTCPlayer.Login].PeerConnection = PeerConnection

					Stream?.getTracks().forEach(track => {
						PeerConnection.addTrack(track, Stream)
					})

					// Remote client is not initiator, so local client is
					if (!RTCPlayer.IsInitiator) {
						console.log("[VOICE] Sending RTC Offer", RTCPlayer.Login)
						PeerConnection.createOffer().then((Offer: RTCSessionDescriptionInit) => {
							PeerConnection.setLocalDescription(Offer)
	
							SocketRef.current?.send(JSON.stringify({
								...DEFAULT_MESSAGE_RTCOFFER,
								From: GameState.LocalPlayer.Login,
								To: RTCPlayer.Login,
								Payload: Offer
							}))
						})						
					}

					PeerConnection.addEventListener('icecandidate', event => {
						if (event.candidate) {
							console.log("[VOICE] Sending IceCanditate", RTCPlayer.Login)
							SocketRef.current?.send(JSON.stringify({
								...DEFAULT_MESSAGE_RTCICECANDIDATE,
								From: GameState.LocalPlayer.Login,
								To: RTCPlayer.Login,
								Payload: event.candidate
							}))
						}
					})
					PeerConnection.addEventListener('connectionstatechange', () => {
						console.log("[VOICE] ConnectionState changed", PeerConnection.connectionState, RTCPlayer.Login)
					})
					PeerConnection.addEventListener('signalingstatechange', () => {
						console.log("[VOICE] SignalingState changed", PeerConnection.signalingState, RTCPlayer.Login)
					})
					PeerConnection.addEventListener('icegatheringstatechange', () => {
						console.log("[VOICE] IceGatheringState changed", PeerConnection.iceGatheringState, RTCPlayer.Login)
					})
					PeerConnection.addEventListener('track', (event) => {
						console.log("[VOICE] Track received", RTCPlayer.Login)

						const stream = event.streams[0]

						// dummy audio because of a chromium bug https://bugs.chromium.org/p/chromium/issues/detail?id=121673#c121
						const dummyAudio = new Audio()
						dummyAudio.srcObject = stream

						const Source = MainAudioContextRef.current!.createMediaStreamSource(stream)

						const PlayerGain = MainAudioContextRef.current!.createGain();
						PlayerGain.gain.value = 0.8;

						const Panner = MainAudioContextRef.current!.createPanner()

						Panner.refDistance = 0.1
						Panner.panningModel = 'equalpower'
						Panner.distanceModel = 'linear'
						Panner.maxDistance = UserConfigRef.current.Distance
						Panner.rolloffFactor = 1

						Source.connect(PlayerGain)
						PlayerGain.connect(Panner)
						Panner.connect(MasterGainNodeRef.current!)

						RTCConnectionsRef.current[RTCPlayer.Login].PlayerGain = PlayerGain
						RTCConnectionsRef.current[RTCPlayer.Login].Panner = Panner
						RTCConnectionsRef.current[RTCPlayer.Login].DummyAudioDom = dummyAudio

						SetVoiceState((PrevVoiceState) => {
							PrevVoiceState.Players[RTCPlayer.Login] = { GainValue: PlayerGain.gain.value, Muted: false }
							return PrevVoiceState
						})
					})
				})
				break
			}
			case EMessageType.RemoveRTCPlayer: {
				if (RTCConnectionsRef.current.hasOwnProperty(message.Login)) {
					console.log("[VOICE] Remove RTCPlayer", message.Login)
					RTCConnectionsRef.current[message.Login].PeerConnection?.close()
					delete RTCConnectionsRef.current[message.Login]

					SetVoiceState((PrevVoiceState) => {
						if (PrevVoiceState.Players.hasOwnProperty(message.Login)) {
							delete PrevVoiceState.Players[message.Login]
						}
						return PrevVoiceState
					})
				}
				break
			}
			case EMessageType.RTCOffer: {
				console.log("[VOICE] Received RTCPlayer Offer", message.From)
				if (RTCConnectionsRef.current.hasOwnProperty(message.From)) {
					RTCConnectionsRef.current[message.From].PeerConnection?.setRemoteDescription(message.Payload)

					RTCConnectionsRef.current[message.From].PeerConnection?.createAnswer().then((Answer: RTCSessionDescriptionInit) => {
						RTCConnectionsRef.current[message.From].PeerConnection?.setLocalDescription(Answer)

						SocketRef.current?.send(JSON.stringify({
							...DEFAULT_MESSAGE_RTCANSWER,
							From: GameState.LocalPlayer.Login,
							To: message.From,
							Payload: Answer
						}))

					})
				}
				break
			}
			case EMessageType.RTCAnswer: {
				console.log("[VOICE] Received RTCPlayer Answer", message.From)
				if (RTCConnectionsRef.current.hasOwnProperty(message.From)) {
					if (RTCConnectionsRef.current[message.From].PeerConnection?.signalingState === "stable") {
						console.warn("[VOICE] can't set RemoteDescription, already stable")
						return
					}
					RTCConnectionsRef.current[message.From].PeerConnection?.setRemoteDescription(message.Payload)
				} else {
					console.warn("[VOICE] Can't find RTC connection")
				}
				break
			}
			case EMessageType.RTCIceCandidate: {
				console.log("[VOICE] Received RTCPlayer RTCIceCandidate", message.From)
				if (RTCConnectionsRef.current.hasOwnProperty(message.From)) {
					console.log("[VOICE] Adding Ice Candidate", message.From)
					RTCConnectionsRef.current[message.From].PeerConnection?.addIceCandidate(message.Payload)
				} else {
					console.warn("[VOICE] Can't find RTC connection")
				}
				break
			}
			default: {
				console.warn("[VOICE] Unknown message type", message)
				break
			}
		}
	}

	const OnError = (event: Event) => {
		console.error('[VOICE] Error with the Websocket', event)
		clearValues()

		SetSocketWaitForReconnect(true)
		setTimeout(() => {
			SetSocketWaitForReconnect(false)
		}, 1000)
	}
	const OnClose = () => {
		console.log('[VOICE] Websocket closed')
		clearValues()

		SetSocketWaitForReconnect(true)
		setTimeout(() => {
			SetSocketWaitForReconnect(false)
		}, 1000)
	}

	function getUserMediaConstraints() {
		if (SettingsState.AudioDevice === null) {
			return {audio: true, video: false}
		}

		return {audio: {deviceId: {ideal: SettingsState.AudioDevice.deviceId}}, video: false}
	}

	function updateAudioDevices() {
		navigator.mediaDevices.enumerateDevices().then((devices) => {
			let AudioDevices: MediaDeviceInfo[] = []
			devices.forEach((device) => {
				if (device.kind !== "audioinput") return
				AudioDevices.push(device)
			})

			SetVoiceState((PrevVoiceState) => ({ ...PrevVoiceState, AudioDevices: AudioDevices}))
		})
	}

	function setGain(GainNode: GainNode|null, Value: number, Muted: boolean) {
		if (GainNode == null) return

		if (Muted) {
			GainNode.gain.value = 0.
		} else {
			GainNode.gain.value = Value
		}
	}

	function clearValues() {
		SocketRef.current = null
		SetCurrentServer("")
		cleanRTCConnections()
		SetVoiceState((PrevVoiceState) => ({ ...DEFAULT_VOICESTATE, AudioDevices: PrevVoiceState.AudioDevices}))
		UserConfigRef.current = DEFAULT_MESSAGE_USERCONFIG
	}

	function cleanRTCConnections() {
		console.log("[VOICE] Closing all RTC connection")
		Object.entries(RTCConnectionsRef.current).forEach(([Login, IRTCConnection]) => {
			IRTCConnection.PeerConnection?.close()
			IRTCConnection.Panner?.disconnect()
			IRTCConnection.DummyAudioDom?.remove()
		})

		RTCConnectionsRef.current = {}
		SetVoiceState({...VoiceState, Players: {}})
	}

	function UpdateVoiceState() {
		console.log("[VOICE] Update VoiceState from UI")
		SetVoiceState({...VoiceState})
	}

	return (
        <VoiceContext.Provider value={{ VoiceState, UpdateVoiceState }}>
            { children }
        </VoiceContext.Provider>
    )
}