import { useContext } from 'react'
import { GameContext } from '../../../GameContext'
import LoadingAnimation from '../../atoms/LoadingAnimation'
import './style.scss'
import { VoiceContext } from '../../../VoiceContext'

export default function WaitingScreen() {
  const GameState = useContext(GameContext)
  const { VoiceState } = useContext(VoiceContext)

  if (GameState.LocalPlayer.Login === "") {
    return (
      <div className='waitingscreen' >
        <LoadingAnimation height={"6vh"}/>
        <h1>Connecting to the game</h1>
        <span>Download the <a href="https://openplanet.dev/download/next">Openplanet</a> and install the <a href="">PlayerPositionsBridge</a> plugin</span>
      </div>
    )
  } else if (!VoiceState.Connected) {
    return (
      <div className='waitingscreen' >
        <LoadingAnimation height={"6vh"}/>
        <h1>Connecting to the Voice server</h1>
        <span>You just have to wait few seconds</span>
      </div>
    )
  }

  return (null)
}